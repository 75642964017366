<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvEventsList
			:layout=""
		></OvEventsList>

	2020-11-23	init

-->

<template>
	<div class="OvEventsList" :class="elmClasses" v-if="_.size(postGroups)">
		<div class="OvEventsList__inner" :class="elmInnerClasses">
			<!--
			<OvDate :startDate="'2020-10-13'" format="long"></OvDate>
			<OvDate :startDate="'2020-10-13'" format="short"></OvDate>
			<hr/>
			<pre name="filter">{{filter}}</pre>
			-->

			<h4 class="OvEventsList__titleWrapper font--sizeMini font--bold vSpace vSpace--miniTitle" v-if="title">
				{{title}}
			</h4>

			<template v-if="layout=='calendar'">
				<div class="OvEventsList__groups">
					<div class="OvEventsList__group" v-for="(postGroup, i) in postGroups" :key="i">
						<span class="OvEventsList__groupLeft lineCrop font font--bold font--sizeBig">
							<!--
							<span class="OvEventsList__groupTitle OvEventsList__groupTitle--short">{{postGroup.title}}</span>
							-->
							<span class="OvEventsList__groupTitle OvEventsList__groupTitle--short">{{postGroup.longTitle}}</span>
							<span class="OvEventsList__groupTitle OvEventsList__groupTitle--long">{{postGroup.longTitle}}</span>
						</span>
						<div class="OvEventsList__groupRight">
							<router-link class="OvEventsList__event hover hover--listItem"
								v-for="(post, j) in postGroup.items" :key="j"
								:to="{ name: 'OvEventView', params: { slug : post.slug } }"
								:class="getEventClasses( post )">
								<div class="OvEventsList__eventLeft lineCrop">
									<OvDate class="font font--bold"
										:startDate="post.acf.startDate"
										xxformat="jF"
										format="dm"
									></OvDate>
									<br/>
									<OvTime
										:startTime="post.acf.startTime"
										:endTime="post.acf.endTime"
									></OvTime>
									<br/>
								</div>
								<div class="OvEventsList__eventRight">
									<div class="OvEventsList__eventRightText hyphenate">
										<h3 class="OvEventsList__eventTitle lineCrop font font--bold">
											<OvLabel2
												class="OvEventsList__eventTitleLabel font font--sizeSmall"
												:text="app.getPostLabelText( post )"
											></OvLabel2>
											<span v-html="app.getPostTitle( post )"></span>
										</h3>
										<div class="OvEventsList__eventShortDesc">
											{{getPageShortDesc( post)}}
										</div>
										<div class="OvEventsList__eventTimeBelow">
											<OvTime
												:startTime="post.acf.startTime"
												:endTime="post.acf.endTime"
											></OvTime>
										</div>
									</div>
									<div class="OvEventsList__eventRightLabel">
										<div class="OvEventsList__eventRightExpander"></div>
										<span class="linkArrow font XXfont--sizeMedium">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
											  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
											</svg>
										</span>
										<!--
										<div>
											<OvLabel2
												class="OvLabel2--isArrow"
												bgColor="white"
												verticalAlign="top"
												:showArrowAfter="true"
												:routerLink="{ name: 'OvEventView', params: { slug : post.slug } }"
											></OvLabel2>
										</div>
										-->
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</template>

			<template v-if="layout=='innerModule'">
				<div class="OvEventsList__groups">
					<div class="OvEventsList__group" v-for="(postGroup, i) in postGroups" :key="i">
						<router-link class="OvEventsList__event hover hover--listItem"
							v-for="(post, j) in postGroup.items" :key="j"
							:to="{ name: 'OvEventView', params: { slug : post.slug } }"
							:class="getEventClasses( post )">
							<div class="OvEventsList__eventLeft lineCrop">
								<OvDate class="font font--bold"
									:startDate="post.acf.startDate"
									xxformat="jF"
									format="dm"
								></OvDate>
								<br/>
								<OvTime
									:startTime="post.acf.startTime"
									:endTime="post.acf.endTime"
								></OvTime>
								<br/>
							</div>
							<div class="OvEventsList__eventRight">
								<div class="OvEventsList__eventRightText hyphenate">
									<h3 class="OvEventsList__eventTitle lineCrop font font--bold">
										<OvLabel2
											class="OvEventsList__eventTitleLabel font font--sizeSmall"
											:text="app.getPostLabelText( post )"
										></OvLabel2>
										<span v-html="app.getPostTitle( post )"></span>
									</h3>
									<div class="OvEventsList__eventShortDesc">
										{{getPageShortDesc( post)}}
									</div>
									<div class="OvEventsList__eventTimeBelow">
										<OvTime
											:startTime="post.acf.startTime"
											:endTime="post.acf.endTime"
										></OvTime>
									</div>
								</div>
								<div class="OvEventsList__eventRightLabel">
									<!--
									<div>
										<OvLabel2
											verticalAlign="top"
											:text="app.getPostLabelText( post )"
											class="font font--sizeSmall"
										></OvLabel2>
									</div>
									-->
									<div class="OvEventsList__eventRightExpander"></div>
									<span class="linkArrow font XXfont--sizeMedium">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
										  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
										</svg>
									</span>
									<!--
									<div>
										<OvLabel2
											class="OvLabel2--isArrow"
											bgColor="white"
											verticalAlign="top"
											:showArrowAfter="true"
											:routerLink="{ name: 'OvEventView', params: { slug : post.slug } }"
										></OvLabel2>
									</div>
									-->
								</div>
							</div>
						</router-link>
						<!--
						<router-link class="OvEventsList__event hover hover--listItem"
							v-for="(post, j) in postGroup.items" :key="j"
							:to="{ name: 'OvEventView', params: { slug : post.slug } }"
							:class="getEventClasses( post )">
							<div class="OvEventsList__eventLeft lineCrop">
								<OvDate class="font font--bold"
									:startDate="post.acf.startDate"
									format="jF"
								></OvDate>
								<br/>
								<OvTime
									:startTime="post.acf.startTime"
									:endTime="post.acf.endTime"
								></OvTime>
								<br/>
							</div>
							<div class="OvEventsList__eventRight">
								<div class="OvEventsList__eventRightText">
									<h3 class="OvEventsList__eventTitle lineCrop font font--bold" v-html="app.getPostTitle( post )"></h3>
									<div>{{getPageShortDesc( post)}}</div>
								</div>
								<div class="OvEventsList__eventRightLabel">
									<div>
										<OvLabel2
											verticalAlign="top"
											:text="app.getPostLabelText( post )"
											class="font font--sizeSmall"
										></OvLabel2>
									</div>
									<div class="OvEventsList__eventRightExpander"></div>
									<div>
										<OvLabel2
											bgColor="white"
											verticalAlign="top"
											:showArrowAfter="true"
											:routerLink="{ name: 'OvEventView', params: { slug : post.slug } }"
											class="font XXXfont--sizeSmall"
										></OvLabel2>
									</div>
								</div>
							</div>
						</router-link>
						-->
					</div>
				</div>
			</template>

			<template v-if="layout=='widget'">
				<div class="OvEventsList__groups font font--sizeSmall">
					<div class="OvEventsList__group" v-for="(postGroup, i) in postGroups" :key="i">
						<router-link class="OvEventsList__event hover hover--listItem"
							v-for="(post, j) in postGroup.items" :key="j"
							:to="{ name: 'OvEventView', params: { slug : post.slug } }"
							:class="getEventClasses( post )">
							<div class="OvEventsList__eventLeft lineCrop">
								<OvDate class="font font--bold"
									:startDate="post.acf.startDate"
									XXformat="dM"
									format="dm"
								></OvDate>
								<br/>
								<br/>
							</div>
							<div class="OvEventsList__eventRight">
								<div class="OvEventsList__eventRightText hyphenate">
									<h3 class="OvEventsList__eventTitle lineCrop font font--bold">
										<OvLabel2
											class="OvEventsList__eventTitleLabel font font--sizeMini"
											:text="app.getPostLabelText( post )"
										></OvLabel2>
										<span v-html="app.getPostTitle( post )"></span>
									</h3>
									<div>
										<OvLabel2
											class="OvEventsList__eventLabel font font--sizeMini"
											:text="app.getPostLabelText( post )"
										></OvLabel2>
										<span class="OvEventsList__eventShortDesc" v-html="getPageShortDesc( post)"></span>
										<!--<OvLabel :type="app.getPostLabelText( post )"></OvLabel>-->
									</div>
									<div>
										<OvTime
											:startTime="post.acf.startTime"
											:endTime="post.acf.endTime"
										></OvTime>
									</div>
								</div>
								<div class="OvEventsList__eventRightLabel">
									<span class="linkArrow font XXfont--sizeMedium">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
										  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
										</svg>
									</span>
									<!--
									<OvLabel2
										class="OvLabel2--isArrow"
										bgColor="white"
										verticalAlign="top"
										:showArrowAfter="true"
										:routerLink="{ name: 'OvEventView', params: { slug : post.slug } }"
									></OvLabel2>
									-->
								</div>
							</div>
						</router-link>
					</div>
				</div>
			</template>

			<template v-if="showCalendarLink">
				<div class="OvEventsList__footer vSpace vSpace--readMoreFooter flex flex--right">
					<OvLabel2
						:class="{'font--sizeMedium' : ['md', 'sm', 'xs'].includes($mq)}"
						:text="$t('button.moreEvents')"
						bgColor="white"
						textColor="currentColor"
						:routerLink="{ name: 'OvEventsView' }"
					></OvLabel2>
				</div>
			</template>

		</div>
		<!--
		<pre name="posts">{{posts}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import OvDate from '@/components/OvDate.vue'
	import OvTime from '@/components/OvTime.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'

	export default {
		name: 'OvEventsList',
		components: {
			OvDate,
			OvTime,
			OvSpacer,
			OvLabel2,
		},
		mixins: [RestHandler],
		props: {
			perPage: {
				type: [Number, Boolean],
				default: 99,
			},
			layout: {
				type: [String, Boolean],
				//default: 'innerModule',
				default: 'calendar', // innerModule | widget | calendar
			},
			title: {
				type: [String, Boolean],
				default: false,
			},
			filter: {
				type: [Object, Boolean],
				default: false,
			},
			showCalendarLink: {
				type: [String, Boolean],
				//default: 'innerModule',
				default: true,
			},
			exposeIds: {
				type: [Array, Boolean],
				//default: 'innerModule',
				default: false,
			},
			limit: {
				type: [Number, Boolean],
				//default: 'innerModule',
				default: false,
			}
			/*
			showOnlyMainEvents: {
				type: [String, Boolean],
				//default: 'innerModule',
				default: false, // innerModule | widget | calendar
			},
			*/
		},
		data() {
			return {
				posts : null,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push('OvEventsList--layout-' + this.layout)

				if( this.layout == 'innerModule' || this.layout == 'calendar' ){
					classes.push('contentModule')
				}

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.layout == 'innerModule' || this.layout == 'calendar' ){
					classes.push('contentModule__inner maxWidth maxWidth--app hSpace hSpace--app')
				}

				return classes
			},
			postGroups(){
				let doLog      = false
				const posts    = this.posts
				let postGroups = {}
				let addedPosts = 0

				//	groupCollapsed
				// 	group
				if( doLog ){
					console.groupCollapsed('OvEventsList • postGroups')
					console.log('posts:', posts)
				}

				const currentLang = this.$i18n.locale

				// populate postGroups, use filter
				this._.forEach( posts, (o, index)=>{
					const filter      = this.filter
					const startDate   = this._.get( o, 'acf.startDate', '' )
					const parts       = startDate.split('-')
					const monthNumber = parseInt( parts[1] )
					const monthKey    = parts[0] + '-' + parts[1]
					const monthNames  = this._.get( this.app, 'monthNames.' + currentLang, [])
					const limit       = this.limit
					let monthName     = monthNames[monthNumber-1].substring(0, 3)
					let longMonthName = monthNames[monthNumber-1]
					let filterMatches = false

					if( filter.category == 4 && o.acf.pageLabel == "Labor" ){
						filterMatches = true
					}
					if( filter.category == 5 && o.acf.pageLabel == "Schleuse" ){
						filterMatches = true
					}
					if( filter.category == 'belongsTo' && filter.belongsToId && filter.belongsToId == o.acf.belongsTo ){
						filterMatches = true
					}
					if( filter.category == 'all' ){
						filterMatches = true
					}
					if( !filter ){
						filterMatches = true
					}

					// item matches filter
					if( filter && doLog ){
						console.groupCollapsed('check if post matches filter', filterMatches)
						console.log('filter:', this.filter)
						console.log('filter.category:', this.filter.category)
						console.log('filter.belongsToId:', this.filter.belongsToId)
						console.log('---')
						console.log('o.acf.pageLabel', o.acf.pageLabel)
						console.log('o.acf.belongsTo', o.acf.belongsTo)
						console.log('===')
						console.log('filterMatches:', filterMatches)
						console.groupEnd()
					}

					// create month key if not already there
					if( filterMatches && !this._.has( postGroups, monthKey ) ){
						postGroups[monthKey] = {
							title     : monthName,
							longTitle : longMonthName,
							items     : [],
						}
					}

					// add post to month group
					if( filterMatches ){
						const belongsToId = this._.get(o, 'acf.belongsTo')
						const runningExhibitions = this._.filter(this.app.runningExhibitions, { id : belongsToId })

						//	groupCollapsed
						// 	group
						if( doLog ){
							console.groupCollapsed(index, monthKey, 'add post', o.title.rendered)
							console.log('o:', o)
							console.log('belongsToId:', belongsToId)
							console.log('runningExhibitions:', runningExhibitions)
							console.groupEnd()
						}

						if( limit ){
							//console.log('limit:', limit, addedPosts)
							if( addedPosts < limit ){
								postGroups[monthKey].items.push( o )
								++addedPosts
							}
						}
						else{
							postGroups[monthKey].items.push( o )
							++addedPosts
						}

					}

					/*
					console.log('monthNumber:', monthNumber)
					console.log('monthName:', monthName)
					console.log('startDate:', startDate)
					*/
				})

				// remove empty postGroups
				this._.forEach(postGroups, (v, k)=>{
					if( !v.items.length ) delete postGroups[k]
				})

				if( doLog ){
					console.log('postGroups:', postGroups)
					console.groupEnd()
				}

				return postGroups
			},
		},
		methods: {
			fetchEvents( callback = ()=>{}, doLog = false ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/events',
					params : {
						range   : 'nowAndFuture',
						perPage : this.perPage,
						todayOnlyCache : this.$root.getCurrentDateAsYYYYMMDD(),
					},
					callback : (response) => {
						let results = response.data.result
						//results = this._.sortBy( results, [function(o) { return o.acf.startDate }] )
						//results = this._.reverse( results )
						this.posts = results

						if( doLog ){
							//console.groupCollapsed('OvEventsList • fetchEvents() callback')
							console.groupCollapsed('OvEventsList • fetchEvents() callback')
							console.log('results:', results)
							console.groupEnd()
						}

						callback()
					},
				})
			},
			getEventClasses( post ){
				const exposeIds   = this.exposeIds
				const belongsToId = this._.get( post, 'acf.belongsTo' )

				let classes = []

				//console.log('exposeIds:', exposeIds, belongsToId, post)

				if( exposeIds && exposeIds.includes( belongsToId ) ){
					classes.push('OvEventsList__event--isActive')
				}

				return classes
			},
			getPageShortDesc( post ){
				const currentLang   = this.$i18n.locale
				const shortDescs = {
					de : this.app.nl2br( this._.trim( this._.get( post, 'acf.pageShortDesc__de' ) ) ),
					en : this.app.nl2br( this._.trim( this._.get( post, 'acf.pageShortDesc__en' ) ) ),
				}
				const shortDesc = shortDescs[currentLang]

				return shortDesc ? shortDesc : undefined
			},
		},
		created() {},
		mounted() {
			this.fetchEvents( ()=>{})
		},
		destroyed () {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvEventsList { // layout
		//&--layout-calendar &__titleWrapper,
		&--layout-innerModule &__titleWrapper {
			//background-color: yellow;

			/*
			.grid;
			.grid--colGap;
			.grid--setAreas;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }
			@media @mq[sm] { .grid--cols-8; }
			*/

			@media @mq[dt] {
				padding-left: 17%;
			}
		}
		//&--layout-calendar &__titleWrapper,
		&--layout-innerModule &__titleWrapper {
			grid-template-areas: "a a a a a a a a a a a a";
		}

		&--layout-calendar &__group {
			//outline: 1px solid fade( orange, 50 );
			//background-color: fade( orange, 35 );

			.grid;
			.grid--colGap;
			.grid--setAreas;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }
			//@media @mq[sm] { .grid--cols-8; }

			grid-template-areas: "a a a b b b b b b b b b";

			@media @mq[md] {
				grid-template-areas: "a a a a a a a a"
									 "b b b b b b b b";
			}
		}

		&--layout-innerModule &__groups {
			.grid;
			.grid--colGap;
			//.grid--setAreas;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }

			& > * { grid-column: 3 / span 10; }

			@media @mq[md] {
				& > * { grid-column: 1 / span 8; }
			}
		}

		&--layout-innerModule &__event,
		&--layout-calendar &__event {
			//outline: 1px solid fade( cyan, 50 );
			//background-color: fade( cyan, 35 );

			.grid;
			.grid--colGap;
			.grid--setAreas;

			@media @mq[dt] { .grid--cols-9; }
			@media @mq[md] { .grid--cols-9}
			@media @mq[sm] { .grid--cols-9; }

			grid-template-areas: "a a b b b b b b b";
			@media @mq[md] {
				grid-template-areas: "a a b b b b b b b";
			}
		}
		&--layout-widget &__event {
			//outline: 1px solid fade( cyan, 50 );
			//background-color: fade( cyan, 35 );

			.grid;
			.grid--colGap;
			.grid--setAreas;

			@media @mq[dt] { .grid--cols-4; }
			@media @mq[md] { .grid--cols-4; }
			@media @mq[sm] { .grid--cols-4; }

			grid-template-areas: "a b b b";

			&Right {
				display: flex;

				&Text {
					//background-color: fade( red, 50 );
					flex-grow: 1;
				}
				&Label {
					//background-color: fade( red, 20 );
					white-space: pre;
				}
			}
		}

		&--layout-calendar &__eventRight,
		&--layout-innerModule &__eventRight {
			display: flex;

			&Text {
				//background-color: fade( blue, 50 );
				flex-grow: 1;
			}
			&Label {
				//background-color: fade( red, 50 );
				display: flex;
				flex-shrink: 0;
			}
		}
	}

	.OvEventsList { // styling
		&__titleWrapper {}
		&__group {
			border-top: 3px solid currentColor;
			&:last-child { border-bottom: 3px solid currentColor; }
		}
		&__groupLeft { // Apr September
			padding-top: 0.3em;

			@media @mq[md] {
				padding-top: 0.6em;
				padding-bottom: 0.6em;
				padding-left: 0.185em;
				border-bottom: 3px solid;
			}
		}
		&__groupTitle { // Sep or September
			//background-color: fade( red, 20 );

			@media @mq[dt] {
				&--long { display: none; }
			}
			@media @mq[md] {
				&--short { display: none; }
			}
		}
		&__event {
			padding-top: 0.35em;
			padding-bottom: 0.35em;
			display: block;
			color: inherit;

			@media @mq[md] {
				padding-top: 0.4em;
				padding-bottom: 0.3em;
			}

			// indent date and infos to show hover rect with padding
			& > *:first-child { padding-left: 0.3em; }
			& > *:last-child { padding-right: 0.5em; }

			/*
			@media @mq[md] {
				& > *:first-child { padding-left: 0em; }
				& > *:last-child { padding-right: 0em; }
			}
			*/

			& + & { border-top: 3px solid currentColor; }

			&Left .OvTime {
				@media @mq[md] {
					display: none;
				}
			}

			&Title {
				padding-right: 2em;

				br { display: none; }

				@media @mq[md] {
					hyphens: auto;
					padding-bottom: 0.2em;
				}
			}
			&Title .OvLabel2 {
				//outline: 1px solid red;
				margin-right: 0.5em;
				transform: translateY(-30%);

				@media @mq[md] {
					margin-right: 0em;
					transform-origin: top left;
					transform: translateY(-3%) scale(0.85);
				}
			}
			&ShortDesc {
				&:empty { display: none; }

				@media @mq[md] {
					hyphens: auto;
				}
			}
			&TimeBelow {
				&:empty { display: none; }

				@media @mq[dt] {
					display: none;
				}
			}
		}

		&--layout-widget &__event {
			&Title {
				padding-bottom: 0.2em;
			}
			&Title .OvLabel2 {
				transform: translateY(-21%);
				margin-right: 0.5em;

				@media @mq[dt] { display: none; }
				@media @mq[md] { display: inline-flex; }
			}
			&ShortDesc {
				//background-color: fade( red, 20 );
				display: block;
				padding-right: 0.5em;

				&:empty{ display: none; }
			}
			&Label {
				transform: translateY(-30%);
				@media @mq[md] { display: none; }
			}
		}

		&__footer {
			//padding-top: 0.85em;
			//padding-right: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
